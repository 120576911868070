<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6ZM16 18V15.9688H11.5156L12.9844 14.2734C13.4115 13.8516 13.7917 13.4531 14.125 13.0781C14.4635 12.7031 14.75 12.3385 14.9844 11.9844C15.2188 11.625 15.3984 11.263 15.5234 10.8984C15.6484 10.5339 15.7109 10.1536 15.7109 9.75781C15.7109 9.0651 15.5651 8.47396 15.2734 7.98438C14.987 7.48958 14.5599 7.11198 13.9922 6.85156C13.4245 6.59115 12.7214 6.46094 11.8828 6.46094C11.0807 6.46094 10.375 6.63542 9.76562 6.98438C9.15625 7.32812 8.67969 7.78906 8.33594 8.36719C7.9974 8.94531 7.82812 9.58333 7.82812 10.2812H10.4688C10.4688 9.94271 10.5234 9.63802 10.6328 9.36719C10.7474 9.09635 10.9062 8.88281 11.1094 8.72656C11.3125 8.57031 11.5495 8.49219 11.8203 8.49219C12.0911 8.49219 12.3177 8.54948 12.5 8.66406C12.6875 8.77865 12.8281 8.94792 12.9219 9.17188C13.0208 9.39062 13.0703 9.66406 13.0703 9.99219C13.0703 10.1849 13.0234 10.401 12.9297 10.6406C12.8411 10.8802 12.6979 11.1484 12.5 11.4453C12.3021 11.737 12.0417 12.0677 11.7188 12.4375L8.0625 16.2812V18H16Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
