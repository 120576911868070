<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.29289 14.7071C7.90237 14.3166 7.90237 13.6834 8.29289 13.2929L11.2858 10.3C11.6803 9.90553 12.3198 9.90556 12.7142 10.3L15.7071 13.2929C16.0976 13.6834 16.0976 14.3166 15.7071 14.7071C15.3166 15.0976 14.6834 15.0976 14.2929 14.7071L13 13.4142L13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18L11 13.4142L9.70711 14.7071C9.31658 15.0976 8.68342 15.0976 8.29289 14.7071Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.36086 2.92931C4.72762 2.35066 5.36504 2 6.05013 2H17.9499C18.635 2 19.2724 2.35066 19.6391 2.92931L21.5339 5.91873C21.8384 6.39908 22 6.95607 22 7.52477V18.8889C22 20.5457 20.6569 21.8889 19 21.8889H5C3.34315 21.8889 2 20.5457 2 18.8889V7.52477C2 6.95607 2.16165 6.39908 2.4661 5.91874L4.36086 2.92931ZM17.9499 4L6.05013 4L4.15537 6.98943L4.14876 7L19.8512 7L19.8446 6.98943L17.9499 4ZM4 18.8889V9L20 9V18.8889C20 19.4412 19.5523 19.8889 19 19.8889H5C4.44772 19.8889 4 19.4412 4 18.8889Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
