<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9155 2.10532C14.2254 2.30408 14.3155 2.71645 14.1168 3.02639L7.33419 13.6028C7.06328 14.0252 6.46086 14.065 6.1368 13.6818L2.15763 8.97625C1.91988 8.6951 1.95506 8.27446 2.23621 8.03672C2.51735 7.79897 2.93799 7.83415 3.17573 8.11529L6.64125 12.2134L12.9944 2.30662C13.1931 1.99669 13.6055 1.90656 13.9155 2.10532Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1D1E20' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
