<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6ZM11.7422 11.2109H10.4609V11.8594V12.5547V13.1719H11.7422C12.0807 13.1719 12.3698 13.224 12.6094 13.3281C12.8542 13.4323 13.0391 13.599 13.1641 13.8281C13.2943 14.0573 13.3594 14.3646 13.3594 14.75C13.3594 15.0052 13.2995 15.237 13.1797 15.4453C13.0599 15.6536 12.8854 15.8203 12.6562 15.9453C12.4323 16.0651 12.1589 16.125 11.8359 16.125C11.5755 16.125 11.3333 16.0677 11.1094 15.9531C10.8854 15.8385 10.7057 15.6849 10.5703 15.4922C10.4401 15.2943 10.375 15.0755 10.375 14.8359H7.73438C7.73438 15.4193 7.85156 15.9219 8.08594 16.3438C8.32552 16.7604 8.64062 17.1042 9.03125 17.375C9.42188 17.6406 9.85417 17.8385 10.3281 17.9688C10.8021 18.0938 11.276 18.1562 11.75 18.1562C12.3542 18.1562 12.9141 18.0833 13.4297 17.9375C13.9453 17.7865 14.3958 17.5677 14.7812 17.2812C15.1667 16.9948 15.4661 16.6432 15.6797 16.2266C15.8932 15.8099 16 15.3359 16 14.8047C16 14.3307 15.9089 13.9115 15.7266 13.5469C15.5443 13.1771 15.2839 12.8672 14.9453 12.6172C14.6886 12.4276 14.3945 12.274 14.063 12.1563C14.3239 12.0334 14.5607 11.8876 14.7734 11.7188C15.1016 11.4583 15.3568 11.1562 15.5391 10.8125C15.7214 10.4688 15.8125 10.1016 15.8125 9.71094C15.8125 9.0026 15.6406 8.40885 15.2969 7.92969C14.9583 7.44531 14.4844 7.08073 13.875 6.83594C13.2656 6.58594 12.5573 6.46094 11.75 6.46094C11.0729 6.46094 10.4401 6.58594 9.85156 6.83594C9.26823 7.08073 8.79688 7.4349 8.4375 7.89844C8.08333 8.35677 7.90625 8.90885 7.90625 9.55469H10.5469C10.5469 9.33073 10.6094 9.14062 10.7344 8.98438C10.8646 8.82812 11.0286 8.70833 11.2266 8.625C11.4245 8.53646 11.6276 8.49219 11.8359 8.49219C12.1432 8.49219 12.3958 8.55208 12.5938 8.67188C12.7917 8.78646 12.9375 8.9401 13.0312 9.13281C13.1302 9.32552 13.1797 9.53646 13.1797 9.76562C13.1797 10.0625 13.1276 10.3203 13.0234 10.5391C12.9193 10.7526 12.7604 10.9193 12.5469 11.0391C12.3385 11.1536 12.0703 11.2109 11.7422 11.2109Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
