<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2C8.34315 2 7 3.34315 7 5H5C3.89543 5 3 5.89543 3 7H4.06443H6.06887H17.9311H19.9355H21C21 5.89543 20.1046 5 19 5H17C17 3.34315 15.6569 2 14 2H10ZM15 5C15 4.44772 14.5523 4 14 4H10C9.44772 4 9 4.44772 9 5H15ZM10.4971 10.4233C10.4547 9.87265 9.97396 9.46059 9.4233 9.50295C8.87265 9.5453 8.46059 10.026 8.50295 10.5767L9.00295 17.0767C9.0453 17.6274 9.52604 18.0394 10.0767 17.9971C10.6274 17.9547 11.0394 17.474 10.9971 16.9233L10.4971 10.4233ZM15.4971 10.5767C15.5394 10.026 15.1274 9.5453 14.5767 9.50295C14.026 9.46059 13.5453 9.87265 13.5029 10.4233L13.0029 16.9233C12.9606 17.474 13.3726 17.9547 13.9233 17.9971C14.474 18.0394 14.9547 17.6274 14.9971 17.0767L15.4971 10.5767ZM6.13554 8L6.8733 19.0665C6.90832 19.5918 7.34462 20 7.87109 20H16.1289C16.6553 20 17.0916 19.5918 17.1267 19.0665L17.8644 8H19.8689L19.1222 19.1995C19.0172 20.7754 17.7083 22 16.1289 22H7.87109C6.2917 22 4.98279 20.7754 4.87773 19.1995L4.1311 8H6.13554Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
